
import { Vue, Options } from 'vue-class-component';
import { BaseButton, TriageSelect } from '@/lib/components';
import { Consult, Patient, Review, TriageStatus } from '@/models';
import { ConsultService, PatientReviewService, TriageStatusService } from '@/services/api';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import ScansPage from '@/views/image-review/ScansPage.vue';
import Diagnosis from '@/views/image-review/Diagnosis.vue';
import Management from '@/views/image-review/Management.vue';
import PatientSummary from '@/views/image-review/PatientSummary.vue';
import Correspondence from '@/views/image-review/Correspondence.vue';
import Confirmation from '@/views/image-review/Confirmation.vue';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useHistoryStore } from '@/stores/history.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    consultId: {
      type: String,
      required: true
    },
    reviewId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      default: null
    }
  },
  components: {
    TriageSelect,
    BaseButton,
    Confirmation,
    Correspondence,
    Diagnosis,
    Management,
    PatientSummary,
    ScansPage,
    WorkflowLayout
  }
})
export default class ImageReview extends Vue {
  reviewId!: string | null;
  patientId!: string;
  consultId!: string;
  consult?: Consult;
  documentId = '';
  review?: Review;
  reviewService = new PatientReviewService(this.patientId);
  managementPlanId = '';
  patient!: Patient | null;
  loading = true;
  patientCorrespondenceLoading = false;
  completeManagement = false; // TODO: update to true at the endpoint this review workflow

  consultService = new ConsultService();
  historyStore = useHistoryStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  request!: CancelTokenSource | null;
  clinicId: string | null = null;
  triageStatuses: Array<TriageStatus> = [];
  triageStatusService: TriageStatusService = new TriageStatusService();
  consultTriageStatusId: string | null = null;
  triageLoading = false;

  get steps(): string[] {
    return [
      this.$t('custom.uhb.review.summary.patient-summary'),
      this.$t('platform.patient.scans'),
      this.$t('custom.uhb.diagnosis.diagnosis'),
      this.$t('custom.uhb.management.management'),
      this.$t('custom.uhb.correspondence.patient'),
      this.$t('platform.common.confirmation')
    ];
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get step() {
    // We want the URL param to be 1-based, but the value in the component to be zero-based
    return Number(this.$route.query.step || 1) - 1;
  }

  async created() {
    try {
      await this.fetchConsult();
      await this.fetchOrCreateReview();

      if (this.clinicId && this.clinicId.length) {
        await this.fetchTriageStatuses(this.clinicId);
      }
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.review.loading-error')
      });
      this.cancel();
    } finally {
      this.loading = false;
    }
  }

  async fetchConsult() {
    try {
      this.request = axios.CancelToken.source();

      this.consult = await this.consultService.fetch(this.consultId, {
        params: {
          organisationId: this.organisationId,
          cancelToken: this.request.token
        }
      });
      this.clinicId = this.consult.clinic.id;
      if (this.consult.triage_status) {
        this.consultTriageStatusId = this.consult.triage_status.id;
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.error-fetching-consult')
        });
      }
    } finally {
      this.request = null;
    }

    // TODO: set this.patient to this.consult.patient when it'll include the patient mrn
  }

  async fetchOrCreateReview() {
    if (this.consult && this.consult.review && this.consult.review.id) {
      this.review = this.consult.review;
    } else {
      this.review = await this.newReview();
    }

    if (!this.reviewId) {
      this.$router.replace({
        path: `${this.$route.path}/${this.review.id}`,
        query: {
          ...this.$route.query
        }
      });
    }
  }

  async fetchTriageStatuses(clinicId: string) {
    this.triageLoading = true;
    try {
      this.request = axios.CancelToken.source();
      const requestConfig: AxiosRequestConfig = {
        params: {
          ...(clinicId ? { 'filter[clinic_id]': clinicId } : {}),
          cancelToken: this.request.token
        }
      };

      this.triageStatuses = (await this.triageStatusService.index(requestConfig)).data;
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.error-fetching-triage-status')
        });
      }
    } finally {
      this.triageLoading = false;
      this.request = null;
    }
  }

  async newReview() {
    return (
      await this.reviewService.start({
        organisational_unit_id: this.organisationId,
        consult_id: this.consultId
      })
    ).data;
  }

  cancel() {
    const from = this.previousRoutePath ? this.previousRoutePath : 'patient-list';
    this.$router.push({ name: from });
  }

  get previousRoutePath() {
    return this.historyStore.from;
  }

  next() {
    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step + 2) // Add 2 because the URL param is 1-based
      }
    });
  }

  back() {
    if (this.step === 0) {
      this.cancel();
      return;
    }

    // @ts-ignore
    this.$router.push({
      ...this.$route,
      query: {
        ...this.$route.query,
        step: String(this.step) // Add 2 because the URL param is 1-based
      }
    });
  }

  async complete() {
    if (this.reviewId) {
      await this.reviewService.complete(this.reviewId);
    }
    this.$router.push(`/patients/${this.patientId}`);
  }

  beforeUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }
}
